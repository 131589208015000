.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.extraselect {
  display: none;
}

/* ** */
.glow-on-hover {
  width: 220px;
  height: 50px;
  border: none;
  outline: none;
  color: #fff;
  /* background: #111; */
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
}

.glow-on-hover:before {
  content: '';
  background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity .3s ease-in-out;
  border-radius: 10px;
}

.glow-on-hover:active {
  /* color: #000 */
}

.glow-on-hover:active:after {
  background: transparent;
}

.glow-on-hover:hover:before {
  opacity: 1;
}

.glow-on-hover:after {
  z-index: -1;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

/* ** */

.iframe1 {
  width: 100%;
  height: 20rem;
}

.money {
  width: 8rem;
  border-radius: 10px;
  height: 3rem;
  margin-right: 1rem;
  border: 1px solid rgba(9, 111, 152, 0.6);
  /* padding: 5px 10px; */
  padding: 0 5px;


}

.money-wrap {
  display: flex;
  align-items: center;
}

.money:focus {
  outline: none;
}

.money:hover {
  border: 1px solid rgba(9, 111, 152, 0.6);

}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.gimg {
  width: "100%";
  height: "100%";
  object-fit: "cover";
}

.hihello {
  display: flex;
  flex-direction: row;
  margin-top: 14rem;
}

.leftg {
  flex: 1;
  margin-right: 2rem;
}

.rightg {
  padding-left: 15px;
  padding-right: 15px;
  flex: 1;
  border-radius: 10px;
}

/* @media (max-width: "800px") {
  .semi-card {
    width: 100%;
  }
} */
@media screen and (max-width: 769px) {
  .hihello {
    flex-direction: column;
  }

  .leftg {
    flex: 1;
    margin-right: 0rem;
    margin-bottom: 2rem;
  }

  #lang-select {
    width: 30%;
    margin: auto;
  }

  .ex2 {
    display: none;
  }

  .extraselect {
    display: block;
  }
}

.semi-card {
  height: 450px;
  width: 350px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
}

/* nav */

/* .navbar {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background: transparent;
  z-index: 99;
  padding-right: 0;
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 0;
  height: 90px;
}

.navbar .icon-bar {
  color: #fff;
}

.navbar .navbar-nav .nav-link {
  font-size: 16px;
  font-weight: 400;
  color: #c6a409;
  margin: 3px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}

.navbar .navbar-nav .nav-link.nav-color {
  color: #fff;
}

.navbar .navbar-nav .nav-link:hover {
  color: #faee07;
} */


.submit-button {
  color: #fff;
  border: 1px white solid;
  transition: 0.3s all;
  cursor: pointer;
}

.submit-button:hover {
  opacity: 0.7;
}

.card{
  width: 100%;
  height: 300px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #dde3ed;
  margin-top: 40px;
}
.card img{
  height: 200px;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}