* {
  margin: 0;

  padding: 0;

  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "Poppins";
}

/* body {

font-family: cursive;

} */

a {
  text-decoration: none;
  color: white;
}

li {
  list-style: none;
}
.n-navbar {
  z-index: 9999;
  display: flex;
  align-items: center;
  align-items: center;
  text-decoration: none;
  justify-content: space-between;
  backdrop-filter: blur(7px);
  color:#023f57;
  padding: 10px 60px;
  background-color: rgba(138, 168, 180, 0.6);

  position: fixed;
  top: 0;
  width: 100%;
  height: 96px;
  /* background-color: teal; */
}

.ncont {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* select */

/* Style the arrow inside the select element: */

#lang-select {
  background-color: #023f57;
}
.nav-links a {
  color:#023f57 ;
  transition: 0.3s;
  text-decoration: none;
  font-weight: 900;
}
.nav-links a:hover {
  color: #d32ca2
;
}

/* LOGO */

.nlogo img {
  width: 60px;
}

/* NAVBAR MENU */

.menu {
  display: flex;

  gap: 1em;

  font-size: 18px;
  padding-top: 1rem;
}

.menu li:hover {
  color: yellow;

  transition: 0.3s ease;
}

/* DROPDOWN MENU */

.services {
  position: relative;
}

/*RESPONSIVE NAVBAR MENU STARTS*/

/* CHECKBOX HACK */

input[type="checkbox"] {
  display: none;
}

/*HAMBURGER MENU*/

.hamburger {
  display: none;
  font-size: 24px;
  user-select: none;
  color: #023f57;
  margin-top: 1rem;
}
select {
  background-color: #023f57;
  width: 5rem;
}
#lang-select {
  width: 7rem;
  /* padding: 2px 10px; */
}

/* APPLYING MEDIA QUERIES */

@media (max-width: 768px) {
  .menu {
    display: none;
    position: absolute;
    background-color: rgba(138, 168, 180, 0.6);
    top: 5.99rem;
    right: 0;
    left: 0;
    text-align: center;
    padding: 16px 0;
  }
  ul{
    justify-content:center;
  
  }
  li{
    justify-content: flex-start;
    display: block;

  }
  select {
    width: 50%;
  }

  .menu li:hover {
    display: inline-block;
    /* background-color:#4c9e9e; */
    transition: 0.3s ease;
  }

  .menu li + li {
    margin-top: 12px;
  }

  input[type="checkbox"]:checked ~ .menu {
    display: block;
  }

  .hamburger {
    display: block;
  }
}
